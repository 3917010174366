import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';

import { Product, ProjectedOrder } from '#mrktbox/types';
import {
  useNavigation,
  useScheduling,
  useOptions,
  useSubscriptions,
} from '#mrktbox';
import {
  generateRange,
  formatCurrency,
  formatDateTime,
  formats,
} from '#mrktbox/utils';

import { Theme } from '#types';

import useModal from '#hooks/useModal';
import useCatalogue from '#hooks/useCatalogue';
import useRequests from '#hooks/useRequests';

import Card from '#materials/Card';
import ButtonStyled from '#materials/ButtonStyled';
import { AlertCircle } from '#materials/icons';

import OrderImages from '#components/orders/OrderImages';
import OrderTag from '#components/orders/OrderTag';
import SkipOrder from '#components/orders/SkipOrder';

interface Style { theme? : Theme; }
interface AlertStyle extends Style { error? : boolean; }

const Alert = styled.p<AlertStyle>`
  display: flex;
  margin: ${(props) => props.theme.layout.spacing.xxsmall} 0 0;
  gap: ${(props) => props.theme.layout.spacing.xxsmall};
  align-items: top;
  justify-content: center;

  color: ${(props) => props.error
    ? props.theme.palette.background.text.alert
    : props.theme.palette.background.text.primary
  };

  & > svg {
    flex-shrink: 0;
  }

  & > span {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
    line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};
  }
`;

interface OrderCardProps {
  order : ProjectedOrder;
}

function OrderCard({ order } : OrderCardProps) {
  const { navigate } = useNavigation();
  const { openModal, closeModal } = useModal();
  const {
    getIterationDivision,
    calculateTime,
    calculateDuration,
  } = useScheduling();
  const { validateSelections } = useOptions();
  const { isOrderRecurring, updateOrder } = useSubscriptions();
  const {
    refresh,
    generateOrderUrl,
    canUpdateItem,
    canUpdateOrder,
    isOrderAvailable,
    isOrderStocked,
    calculateCutoff,
    getLineItems,
    editOrder,
    hasCardOnFile,
  } = useRequests();
  const { allProducts, isProductAvailable } = useCatalogue();

  const products = useMemo(() => {
    if (!order || !allProducts) return [];
    return getLineItems(order).map((item) => allProducts[item.productId])
      .filter((p) => !!p) as Product[];
  }, [order, allProducts, getLineItems]);

  const handleEdit = useCallback(() => {
    editOrder(order);
    navigate('/?openCart=true');
  }, [order, editOrder, navigate]);

  const handleSkip = useCallback(() => {
    const iterations = generateRange(4, order.timeSlotIteration + 1);
    const timeSlot = order.timeSlot;
    if (!timeSlot) return;

    const newTimes = iterations.map((i) => {
      const division = order.order?.timeSlotDivision ?? 0;
      const start = calculateTime(timeSlot, i, division);
      const end = !timeSlot.division
        ? new Date(start.getTime() + calculateDuration(timeSlot, i))
        : null;
      const cutoff = calculateCutoff({
        ...order,
        timeSlotIteration : i,
        time : start,
      });
      return { start, end, cutoff };
    }).filter((t) => !!t) as {
      start : Date,
      end : Date | null,
      cutoff : Date,
    }[];

    openModal((
      <SkipOrder
        orderName={order.order
          ? `order #${order.order?.id}`
          : 'upcoming order'}
        newTimes={newTimes}
        onSelect={async (time) => {
          const selection = getIterationDivision(timeSlot, time);
          if (!selection) return false;

          const success = (await updateOrder({
            ...order,
            timeSlotIteration : selection.iteration,
            timeSlotDivision : selection.division,
          }, {
            target : 'future',
            targetTime : {
              timeSlot,
              iteration : order.timeSlotIteration,
              division : order.timeSlotDivision,
            }
          })).success;

          if (success) refresh();
          return success;
        }}
        onCancel={closeModal}
      />
    ));
  }, [
    order,
    refresh,
    calculateCutoff,
    getIterationDivision,
    calculateTime,
    calculateDuration,
    updateOrder,
    openModal,
    closeModal,
  ]);

  const items = Object.values(order.lineItems);
  const open = items.length
    ? Object.values(order.lineItems).every((li) => canUpdateItem(li, order))
    : canUpdateOrder(order);
  const anyAvailable = products.some((p) => isProductAvailable(p, order));
  const available = isOrderAvailable(order);
  const stocked = isOrderStocked(order);
  const isSubscription = isOrderRecurring(order);
  const requireCheckout = order.serviceChannel?.requireCheckout;
  const draft = !order.complete || (requireCheckout && !order.paid);
  const status = draft ? 'draft' : (!anyAvailable ? 'error' : order.status);

  const selectionsValid = useMemo(() => {
    if (!open ||!order.time || !allProducts) return true;
    for (const lineItem of getLineItems(order)) {
      const product = allProducts?.[lineItem.productId] ?? null;
      if (!product) continue;

      const selections = Object.values(order.selections)
        .filter((s) => s.lineItemId === lineItem.id);
      if (!validateSelections(product, selections, order.time).valid) {
        return false;
      }
    }
    return true;
  }, [order, allProducts, open, getLineItems, validateSelections]);

  const total = order.totals.find((t) => t.key === 'total')?.total ?? null;

  const orderType = (order.serviceChannel?.name ?? 'Order') + ((
    !order.serviceChannel?.name?.toLowerCase().endsWith('order')
      && !order.serviceChannel?.name?.toLowerCase().endsWith('orders')
  ) ? ' Order' : '');
  const preface = order.order
    ? `${orderType} #${order.order.id}`
    : (order.status === 'cancelled'
      ? `Cancelled ${orderType}`
      : (order.status === 'fulfilled'
        ? `Past ${orderType}`
        : (order.complete ? `Upcoming ${orderType}` : `Draft ${orderType}`)));

  const title = order.address
    ? 'Delivery Order'
    : (order.location ? `Pickup from ${order.location.name}` : 'New Order');

  const orderLink = order.order
    ? `/orders/${order.order.id}`
    : generateOrderUrl(order);

  const needsCard = !order.serviceChannel?.requireCheckout
    && !order.paid
    && !hasCardOnFile();

  const error = !available || !selectionsValid || needsCard;
  const prompt = useMemo(() => {
    if (!available) return (
      <span>
        { `One or more items in this order are currently unavailable for
          ${order?.serviceChannel?.name ?? 'this order type'}.` }
      </span>
    );
    if (!stocked) return (
      <span>
        { `One or more items in this order are currently out of stock.` }
      </span>
    );
    if (!selectionsValid) return (
      <span>
        { `One or more items in this order require additional customisation.` }
      </span>
    );
    if (needsCard) {
      let appendType = '';
      const channelName = order.serviceChannel?.name ?? 'Order';
      if (channelName.toLowerCase().endsWith('order')) appendType = 's';
      else if (!channelName.toLowerCase().endsWith('orders'))
        appendType = ' orders';

      return (
        <span>
          { `${channelName + appendType} require a card on file to process.` }
        </span>
      );
    }
    if (error) return (
      <span>
        { `Opps! Something went wrong. Please contact us for assistance.` }
      </span>
    );
  }, [
    available,
    stocked,
    selectionsValid,
    needsCard,
    error,
    order,
  ]);

  return (
    <Card
      tag={(
        <OrderTag
          status={status}
          subscription={isSubscription}
        />
      )}
      preface={preface}
      title={title}
      content={
        <>
          <p>
            { !!order.time && formatDateTime(order.time, formats.short) }
            { (total && order.time) && <>&nbsp;|&nbsp;</> }
            { !!total && formatCurrency(total) }
          </p>
          <p>
            { order.address
              ? order.address.description
              : order.location?.address?.description
            }
          </p>
          <OrderImages products={products} order={order}/>
          { error && (
            <Alert error={error}>
              <AlertCircle size={16} />
              { prompt }
            </Alert>
          ) }
        </>
      }
      footer={
        <>
          { (!draft && orderLink) && (
            <ButtonStyled
              href={orderLink}
              size="small"
              colour="secondary"
            >
              Details
            </ButtonStyled>
          ) }
          { open && (
            <ButtonStyled
              size="small"
              onClick={handleEdit}
            >
              Edit
            </ButtonStyled>
          ) }
          { (open && isSubscription) && (
            <ButtonStyled
              size="small"
              onClick={handleSkip}
            >
              Skip
            </ButtonStyled>
          ) }
        </>
      }
    />
  );
}

export default OrderCard;
